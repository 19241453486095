import { useRef, useState } from 'react'
import { useTranslation } from 'next-i18next'
import styled from 'styled-components'
import { equals } from 'ramda'
import { Box, IconExpandMore, Text } from '@machobear/design-system'
import { vars } from '@machobear/design-system/css'
import { Manager, Popper, Reference } from 'react-popper'

import useLanguage from '@/hooks/use-language'
import useOnClickOutside from '@/components/use-onclick-outside'

const LanguageToggle = styled.button`
  padding: 4px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: relative;
  background-color: ${vars.foregroundColor.white};
  border: 1px solid ${vars.borderColor.neutralLight};
  border-radius: 16px;
`

const IconWrapper = styled.div<{ isOpen?: boolean }>`
  margin-left: 10px;
  transition: all 0.3s linear;
  transform: rotate(0deg);
  pointer-events: none;

  ${({ isOpen }) => (isOpen ? 'transform: rotate(180deg);' : '')}
`

const Dropdown = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${vars.foregroundColor.white};
  border: 1px solid ${vars.borderColor.neutralLight};
  width: 136px;
  border-radius: 4px;
  cursor: pointer;
  z-index: 1;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.23);
  margin-bottom: 4px;
`

const OptionItem = styled.a<{ active?: boolean }>`
  text-decoration: none;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  border-bottom: 1px solid ${vars.borderColor.neutralLight};

  &:hover {
    background: ${vars.backgroundColor.neutralLight};
  }

  &:last-child {
    border-bottom: none;
  }

  ${props =>
    props.active &&
    `
    opacity: 0.3;
    cursor: default;
  `}
`

type Props = {
  clickId: string
  displayLanguages?: string[]
}

const LanguageSelector: React.FC<Props> = ({ clickId, displayLanguages }) => {
  const { t } = useTranslation()
  const { language, languages, changeLanguage } = useLanguage()
  const langMenuRef = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState(false)
  useOnClickOutside(langMenuRef, () => setIsOpen(false))

  const filteredLanguages = displayLanguages
    ? languages.filter(lang => displayLanguages.includes(lang.value))
    : languages

  return (
    <div ref={langMenuRef}>
      <Manager>
        <Reference>
          {({ ref }) => (
            <LanguageToggle
              aria-label={t(
                'b.language.view.select_language.button',
                'Select language'
              )}
              onClick={() => {
                setIsOpen(!isOpen)
              }}
              ref={ref}
              id={`${clickId}-language-toggle-click`}
            >
              <Box
                background="customLight"
                display="flex"
                alignItems="center"
                justifyContent="spaceBetween"
              >
                <Text size="xsmall" weight="medium" tone="neutral">
                  {language?.label}
                </Text>
                <IconWrapper isOpen={isOpen}>
                  <IconExpandMore />
                </IconWrapper>
              </Box>
            </LanguageToggle>
          )}
        </Reference>
        <Popper placement="top">
          {({ ref, style, placement }) =>
            isOpen && (
              <Dropdown
                style={style}
                ref={ref}
                data-placement={placement}
                onClick={() => {
                  setIsOpen(false)
                }}
              >
                {filteredLanguages.map(option => (
                  <OptionItem
                    key={option.value}
                    active={equals(option, language)}
                    id={`${clickId}-language-toggle-option-${option.value}`}
                    onClick={() => changeLanguage(option.value)}
                  >
                    <Box background="customLight">
                      <Text size="xsmall" weight="medium" tone="neutral">
                        {option.label}
                      </Text>
                    </Box>
                  </OptionItem>
                ))}
              </Dropdown>
            )
          }
        </Popper>
      </Manager>
    </div>
  )
}

export default LanguageSelector
