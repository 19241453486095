import { propEq } from 'ramda'

import useCurrentSession from './use-current-session'

const useSessionComplete = () => {
  const session = useCurrentSession()
  return !!session?.id && propEq('registration_complete', '1', session)
}

export default useSessionComplete
