import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { find, propEq } from 'ramda'
import { useDispatch } from 'react-redux'

import useSessionComplete from '@/hooks/use-session-complete'
import { toast } from '@/components/toast-notifications'

type Language = {
  value: string
  label: string
}

const ENGLISH: Language = {
  value: 'en',
  label: 'Canada (EN)'
}

const LANGUAGES: Language[] = [
  {
    ...ENGLISH
  },
  {
    value: 'fr',
    label: 'Canada (FR)'
  },
  {
    value: 'zh',
    label: '中文 (CN)'
  }
]

function useLanguage() {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const router = useRouter()
  const { pathname, asPath, query } = router
  const isLoggedIn = useSessionComplete()
  const lang = (i18n as any).language
  const languages = LANGUAGES
  const language =
    find<Language>(propEq('value', lang.substring(0, 2)))(languages) || ENGLISH

  const changeLanguage = async (newLanguage: string) => {
    if (isLoggedIn) {
      const response = await dispatch.session.updateSession({
        setting_language: newLanguage
      })
      if (!response?.ok) {
        toast(
          t(
            'b.settings.view.settings.failed_save',
            'Your settings could not be saved.'
          ),
          {
            title: t('common.error', 'Error'),
            type: 'error'
          }
        )
      }
    }
    router.push({ pathname, query }, asPath, { locale: newLanguage })
  }

  return { language, languages, changeLanguage }
}

export default useLanguage
