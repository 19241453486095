import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'

import ENV_VARS from '@/config'
import { breakpoints, colors } from '@/styles'
import useRole from '@/hooks/use-role'
import { WarningIconTriangle } from '@/components/icons'
import { showWarningModalWithCloseButton } from '@/components/warning-modal'

const { DASHBOARD_URL } = ENV_VARS

export const HeaderButton = styled.button<{ color?: string }>`
  font-family: Inter, sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: ${props => props.color || colors.grey08};
  text-align: right;
  background: transparent;
  cursor: pointer;
  border: none;
  padding: 8px;
  margin: 0;
  white-space: nowrap;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
`

const PostButton = styled(HeaderButton)<{ color?: string }>`
  min-width: 120px;
  color: ${props => props.color || colors.grey08};
  flex-shrink: 0;

  @media (max-width: ${breakpoints.ipadMiniMax}) {
    font-size: 12px;
    line-height: 17px;
    min-width: 92px;
  }
`

export const PostListingButton = ({ color }: { color?: string }) => {
  const role = useRole()
  const isLandlord = role === 'landlord'
  const isTenant = role === 'tenant'
  const { t } = useTranslation()
  const router = useRouter()
  const dispatch = useDispatch()
  const logout = () => dispatch.auth.logout()

  const handleClickPostAListing = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation()
    if (isTenant) {
      showWarningModalWithCloseButton({
        title: t(
          'r.landlord.view.logged_in_as_renter.title',
          "Oops! It looks like you're logged in as a renter."
        ),
        message: t(
          'r.landlord.view.logged_in_as_renter.text',
          'To access landlord-specific features, please log out and sign up for a landlord account or log in with your landlord account details.'
        ),
        icon: WarningIconTriangle,
        iconBgColor: '#FCF5D7',
        button: {
          text: t('common.log_out', 'Log Out'),
          onClick: logout
        }
      })
    } else {
      isLandlord
        ? (window.location.href = `${DASHBOARD_URL}/listing/create`)
        : router.push({
            pathname: '/post-a-listing',
            query: { accountType: 'essentials' }
          })
    }
  }
  return (
    <PostButton
      id="btn-post-listing"
      onClick={handleClickPostAListing}
      color={color}
    >
      {t('b.header.view.post_listing.button', 'Post a listing')}
    </PostButton>
  )
}

export default PostListingButton
